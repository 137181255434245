import React from 'react'
import { Link } from 'gatsby'

import './hero.scss'

class HomeHero extends React.Component {
  constructor (props) {
    super(props)
    this.scrollDown = this.scrollDown.bind(this)
  }

  scrollDown (event) {
    event.preventDefault()
    const { offsetTop } = document.getElementById('js-home-services')
    const top = offsetTop - (window.innerWidth < 720 ? 60 : 100)
    window.scrollTo({ top, behavior: 'smooth' })
  }

  render () {
    const { intro: __html, cta: { link, title }, hero } = this.props

    return (
      <div className='b-hero' style={{ backgroundImage: `url('${hero.childImageSharp.fluid.src}')` }}>
        <div className='b-hero-main'>
          <div className='b-hero-main-copy' dangerouslySetInnerHTML={{ __html }} />
          <div className='b-hero-main-btn'>
            <Link to={link} className='b-main-button'>{title}</Link>
          </div>
        </div>
        <a href='#' className='b-hero-down' onClick={this.scrollDown}>&nbsp;</a>
      </div>
    )
  }
}

export default HomeHero
