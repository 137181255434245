import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import getMetaTags from '../utils/seo'

import Layout from '../components/layout'
import HomeHero from '../components/home/hero'
import ServiceList from '../components/home/service_list'
import ServiceItem from '../components/home/service_item'

export const IndexPageTemplate = ({ hero, intro, services: { rent, construction } }) => (
  <div>
    <HomeHero hero={hero} intro={intro} cta={{ link: '/kontakt', title: 'Küsi pakkumist' }} />
    <ServiceList title={'Teenused'}>
      <ServiceItem service={rent} />
      <ServiceItem service={construction} />
    </ServiceList>
  </div>
)

const IndexPage = ({ data: { markdownRemark: { frontmatter: { hero, meta, rent, construction }, html } } }) => (
  <Layout>
    <Helmet title={'Jahho - Ettevõttest'} meta={getMetaTags(meta)} />
    <IndexPageTemplate hero={hero} intro={html} services={{ rent, construction }} />
  </Layout>
)

export default IndexPage

export const indexPageQuery = graphql`
  query IndexPageQuery($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        hero {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        meta {
          description
          tags
        }
        rent {
          title
          description
          link {
            name
            url
          }
          picture {
            childImageSharp {
              fixed(width: 1200, height: 800) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        construction {
          title
          description
          link {
            name
            url
          }
          picture {
            childImageSharp {
              fixed(width: 1200, height: 800) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`
