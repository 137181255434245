import React from 'react'

import './service_list.scss'

const ServiceList = ({ children, title }) => (
  <div className='b-home-services' id='js-home-services'>
    <div className='b-home-services-title'>
      <h2 className='b-main-title m-2'>{title}</h2>
    </div>
    <div className='l-main'>
      {children}
    </div>
  </div>
)

export default ServiceList
