import React from 'react'
import { Link } from 'gatsby'

import './service_item.scss'

const ServiceItem = ({ service: { title, description, link: { name, url }, picture } }) => (
  <div className='b-service-item'>
    <div className='b-service-item-image'>
      <figure style={{ backgroundImage: `url('${picture.childImageSharp.fixed.src}')` }} />
    </div>
    <div className='b-service-item-main'>
      <div className='b-service-item-main-content'>
        <div className='b-service-item-main-content-title'>
          <h3 className='b-main-title m-3'>{title}</h3>
        </div>
        <p className='b-service-item-main-content-description'>{description}</p>
        <div className='b-service-item-main-content-btn'>
          <Link to={url} className='b-main-button m-ol'>{name}</Link>
        </div>
      </div>
    </div>
  </div>
)

export default ServiceItem
